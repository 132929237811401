import {BUSY_MODE_DISPLAY, createSession, createViewport, ISessionApi} from "@shapediver/viewer";
import * as SDV from "@shapediver/viewer";
import $ from "jquery";

(<any>window).SDV = SDV;

let viewport: SDV.IViewportApi;

const paramMap: { [key: string]: string } = {
    materiale: "fe528d13-b455-425f-ad97-d29da142ac18",
    size: "0988c58b-c1fa-47d5-8dc3-d1661288106a",
    stringaDiamanti: "028cdd55-89ab-49ff-b8b3-9de976985db6",
    testo: "8df1e264-ce55-4e1c-8adf-9bb1e4be311b",
    diamanti : "6116695ac69e23e93d97ac32e6f93f6f"
}

let cart = {
    diamond: "",
    diamondColor: "",
    material : "",
    size : "",
    text : "",
    lettersWithDiamond: "",
    variationId: "",
    productId: ""
}

let model = {
    diamond: "0",
    diamondColor: "diamanti-bianchi",
    material : "0",
    size : "30",
    text : "ANELLOANGELA",
    lettersWithDiamond: "0"
}

let lang: string;
let session: ISessionApi

let url = "https://staging.broart.it/textures/diamante.png"
let cubeMap = [url, url, url, url, url, url];

const assignGemMaterial = async (node: SDV.ITreeNode, gemMaterial: SDV.MaterialGemData) => {
    node.traverse((n) => {
        for (let i = 0; i < n.data.length; i++) {
            if (n.data[i] instanceof SDV.GeometryData) {
                (<SDV.GeometryData>(
                    n.data[i]
                )).primitive.material = gemMaterial
            }
        }
    });
    node.updateVersion();
};

const whiteDiamands = new SDV.MaterialGemData({
    refractionIndex: 4.8,
    impurityMap: undefined,
    impurityScale: 0,
    colorTransferBegin: "#ffffff",
    colorTransferEnd: "#dee4ed",
    gamma: 1.3,
    contrast: 1.5,
    brightness: 0,
    dispersion: 0.003,
    tracingDepth: 2,
    tracingOpacity: 1,
    envMap: JSON.stringify(cubeMap)
});

const blueDiamonds = new SDV.MaterialGemData({
    refractionIndex: 2,
    impurityMap: undefined,
    impurityScale: 0,
    colorTransferBegin: "#00458c",
    colorTransferEnd: "#003b79",
    gamma: 1.3,
    contrast: 1.5,
    brightness: 0,
    dispersion: 0.003,
    tracingDepth: 2,
    tracingOpacity: 1,
    envMap: JSON.stringify(cubeMap)
});

const blackDiamonds = new SDV.MaterialGemData({
    refractionIndex: 2.4,
    impurityMap: undefined,
    impurityScale: 0,
    colorTransferBegin: "rgba(0,0,0,0.28)",
    colorTransferEnd: "#000000",
    gamma: 1.3,
    contrast: 1.5,
    brightness: 0,
    dispersion: 0.003,
    tracingDepth: 2,
    tracingOpacity: 1,
    envMap: JSON.stringify(cubeMap)
});

const redDiamonds = new SDV.MaterialGemData({
    refractionIndex: 2,
    impurityMap: undefined,
    impurityScale: 0,
    colorTransferBegin: "#ad0000",
    colorTransferEnd: "#9b0000",
    gamma: 1.3,
    contrast: 1.5,
    brightness: 0,
    dispersion: 0.003,
    tracingDepth: 2,
    tracingOpacity: 1,
    envMap: JSON.stringify(cubeMap)
});

const brownDiamonds = new SDV.MaterialGemData({
    refractionIndex: 2,
    impurityMap: undefined,
    impurityScale: 0,
    colorTransferBegin: "#7e4600",
    colorTransferEnd: "#754100",
    gamma: 1.3,
    contrast: 1.5,
    brightness: 0,
    dispersion: 0.003,
    tracingDepth: 2,
    tracingOpacity: 1,
    envMap: JSON.stringify(cubeMap)
});

(async () => await init())();

async function init() {
    // create a viewport
    viewport = await createViewport({
        canvas: document.getElementById("canvas") as HTMLCanvasElement,
        id: "broArtViewport",
        branding: { busyModeDisplay: BUSY_MODE_DISPLAY.BLUR }
    });

    // create a session
    session = await createSession({
        ticket: "8be9e98c05b0fe64eaabee41fcbc6d28460d958e46c4184a8538c7a9e06160665ab12c186abe1a0632c35beeac8bd515dfb439d9b24c4a2a7cbdb08671ad4386b2ccdeb5248852cb9b6b4079352e215a0699a5e119e30710b573c69bfc9a10b855bb65e96ca05d-98d5e384d948bc0fdfc3084dea3497d3",
        modelViewUrl: "https://sdeuc1.eu-central-1.shapediver.com",
        id: "broArtSession",
        initialParameterValues: {
            [paramMap.testo]: model.text,
            [paramMap.size]: model.size,
            [paramMap.materiale]: model.material,
            [paramMap.stringaDiamanti]: model.lettersWithDiamond
        }
    });

    viewport.gridVisibility = false;
    viewport.groundPlaneVisibility = false;

    const camera = viewport.createPerspectiveCamera();
    camera.position = [-11.0, -22.0, 15.0]
    camera.zoomRestriction = { minDistance: 40, maxDistance: 90 };
    camera.zoomToFactor = 60
    await camera.zoomTo()

    viewport.assignCamera(camera.id)
    viewport.update();

    loadSizeGrid()
    loadSymbols()
    initCart()
    await initVisibleFields()
    attachEventListener()
    loadSelect2ForTS()

    await getIdVariazione()
}

function initCart() {
    const langElem = document.getElementById("plugin")

    if(langElem == null) {
        console.log("Impossibile inizializzare lingua, componente non presente")
        return
    }

    lang = langElem.getAttribute("data-lang") || ""

    if(lang == "") {
        console.log("Impossible impostare variation ID, lingua non impostata")
        return;
    }

    cart.text = model.text
    const sz = document.querySelector(`#sizeGrid > a[data-value="${model.size}"]`) as HTMLAnchorElement
    cart.size = getSize(sz.text)
    const mat = document.querySelector(`#materiale > option[shape-value="${model.material}"]`) as HTMLInputElement
    cart.material = mat.value
    cart.diamondColor = model.diamondColor
    cart.diamond = model.diamond + (lang == "en" ? `-${lang}` : '')
    cart.productId = lang === 'en' ? "10149" : "10060"
}

async function initVisibleFields() {
    await loadDiamondsSelect(cart.text)

    const m = document.getElementById("materiale") as HTMLInputElement
    if (m != null) {
        const valueElem = m.querySelector(`option[shape-value="${model.material}"]`)!
        m.value = valueElem.getAttribute("value")!
    }
    setGemsMaterial("0")
}

function loadSelect2ForTS() {
    const jQuery = require('jquery')
    const select2 = require('select2')()

    if (typeof select2 === 'function') {
        select2(window, jQuery)
    }

    $("#diamanti")
        .select2({
            allowClear: true,
            closeOnSelect: false
        })
}

function loadSymbols() {
    /**
     * --- Set Simboli ---
     * , cuore pieno su (SVG 1)
     * ] cuore pieno giù (SVG 2)
     * . cuore vuoto su (SVG 3)
     * @ cuore vuoto giù (SVG 4)
     * < fulmine (SVG 5)
     * [ infinito (SVG 6)
     * * stella piena (SVG 7)
     * - stella vuota (SVG 8)
     * */

    const symbolValues = [",", "]", ".", "@", "<", "[", "*", "-"];

    const simboli = document.getElementById("simboli")

    if(simboli == null) {
        console.log("Impossibile caricare simboli, container con id 'simboli' non trovato")
        return
    }


    for (let i = 0; i < 8; i++) {
        const div = document.createElement("div")
        div.setAttribute("style", "display: inline-block; width: 30px; height: 30px")
        const img = document.createElement("img")
        img.className = "symbols"
        img.setAttribute("style", "cursor: pointer")
        img.setAttribute("data-value", `${symbolValues[i]}`)
        img.src = `https://broart.it/wp-content/themes/rhythm/AnelloAngela/svg/svg${i + 1}.svg`
        img.addEventListener("click", () => {
            const text = insertSymbol(img)

            if(text == null) {
                console.log("impossibile impostare testo, testo inserito non valido")
                return
            }

            setText(text)
        })
        div.appendChild(img)
        simboli.appendChild(div)
    }
}

function loadSizeGrid() {
    let j = 34;
    let k = 0;
    let q = 0;
    for (let i = 1.0; i <= 30.0; i = i + 0.5) {
        if (k % 6 === 0) {
            j++;
        }

        const sizeGrid = document.getElementById("sizeGrid")

        if(sizeGrid == null) {
            console.log("Impossibile creare griglia taglie, non è stato trovato l'elemento con id 'sizeGrid'")
            return
        }
        const sizeNumber = document.createElement("a")
        const realSize = 30 + q
        sizeNumber.setAttribute("data-value", String(realSize))
        sizeNumber.setAttribute("style", "margin-top: 2px; margin-right: 2px; cursor: pointer")
        sizeNumber.className = `size-numbers ${i === 1 ? 'current' : ''}`
        sizeNumber.text = String(i)
        sizeNumber.onclick = () => {
            updateParameter('size', String(realSize))
                .then(() => setCurrentSize(sizeGrid, sizeNumber))
                .catch(e => console.log(e))
        }

        sizeGrid.appendChild(sizeNumber)
        k++;

        if (Number.isInteger(i)) {
            q += 0.5;
        }
    }
}

async function getIdVariazione() {
    let formData = new FormData();
    formData.append('attribute_pa_diamanti-new', cart.diamond);
    formData.append('attribute_pa_materiale-new', cart.material);
    formData.append('attribute_pa_colore-diamanti-new', cart.diamondColor);
    formData.append('product_id', cart.productId);

    const res = await fetch(`https://broart.it/?wc-ajax=get_variation`, {
        method: 'POST',
        body: formData,
    });

    if(!res.ok) {
        console.error(`error in response ${res.status}`)
        return
    }

    const response = await res.json()

    cart.variationId = response.variation_id
    const currentPrice = document.getElementById("current-price")

    if (currentPrice == null) {
        console.log("Impossibile impostare il prezzo corrente, elemento con id 'current-price' non trovato")
        return
    }
    currentPrice.textContent =  response.display_price + ",00 €"
}

function getSize(size: string): string {
    return size.replace(".", "-") + (lang == "en" ? `- ${lang}` : '')
}

function getText(text: string): string | undefined {
    const errMin = document.getElementById("error-length-min")
    const errMax = document.getElementById("error-length-max")

    if(errMin == null || errMax == null) {
        console.log("Mancano le componenti per visualizzare errori di lunghezza del testo")
        return
    }

    text = text.toUpperCase();
    if (text.length > 21) {
        errMax.style.display = "block"
        return
    } else if (text.length == 0) {
        errMin.style.display = "block"
        return
    } else {
        errMax.style.display = "none"
        errMin.style.display = "none"
    }

    return text
}

function setCurrentSize(grid: HTMLElement, selectedSize: HTMLAnchorElement) {
    const currentSelectedElem = grid.querySelector(".size-numbers.current")

    if(currentSelectedElem != null) {
        currentSelectedElem.className = "size-numbers"
    }

    selectedSize.className += " current"
    // cart.size = getSize(selectedSize.getAttribute("data-value")!)
    cart.size = getSize(selectedSize.text)
}

function setCharAt(str: string, index:number, chr: string): string {
    if(index > str.length -1) return str;
    return str.substring(0, index) + chr + str.substring(index +1);
}

async function setDiamanti() {
    const diamanti = $('#diamanti').select2('data')
    const diamErr = document.getElementById("error-length-min")

    if (diamErr == null) {
        console.log("Manca il messaggio errore dei diamanti, aggiungerlo!")
        return
    }

    if (diamanti.length <= 10) {
        diamErr.style.display = "none"

        const text = document.getElementById("primaryText") as HTMLInputElement

        if (text == null) {
            console.log("Impossibile aggiungere diamante, testo non trovato")
            return
        }

        const textLength = text.value.length;
        let strDiamanti = "0".repeat(textLength);
        diamanti.forEach(element => strDiamanti = setCharAt(strDiamanti, +element.id - 1, '1'))

        await updateParameter("stringaDiamanti", strDiamanti)

        let lettersWithDiamond = ""
        for (let i = 0; i < diamanti.length; i++) {
            lettersWithDiamond += "(" + diamanti[i].text + ") Pos" + diamanti[i].id + " / "
        }

        $("#fake-submit").attr("disabled", "disabled")
        cart.diamond = String(diamanti.length) + (lang == "en" ? `-${lang}` : "")
        cart.lettersWithDiamond = lettersWithDiamond
        await getIdVariazione();
    } else {
        diamErr.style.display = "block"
    }
}

function setGemsMaterial(id: string) {
    let material : SDV.MaterialGemData;
    switch (id) {
        case "0":
            material = whiteDiamands;
            break;
        case "1":
            material = blackDiamonds;
            break;
        case "2":
            material = brownDiamonds;
            break;
        case "3":
            material = blueDiamonds;
            break;
        case "4":
            material = redDiamonds;
            break;
        default:
            material = whiteDiamands
            break;
    }

    let diamanti = session.getOutputById(paramMap.diamanti);
    // @ts-ignore
    diamanti.updateCallback = (
        newNode?: SDV.ITreeNode,
        oldNode?: SDV.ITreeNode
    ) => {
        if (newNode) {
            // we assign the material to the node
            assignGemMaterial(newNode, material);
            // and update to see the changes
            newNode.updateVersion();
            viewport.update();
        }
    };

    // we call this update callback once, to see our applied changes
    // @ts-ignore
    diamanti.updateCallback(diamanti.node);
}

function setText(inputText: string) {
    const text = getText(inputText)

    if(text == null) {
        console.log("Testo non impostabile")
        return
    }

    updateParameter("testo", text)
        .then(() => loadDiamondsSelect(text))
        .then(() => updateParameter("stringaDiamanti", "0000000000000000")) //Reset diamanti
        .catch(e => console.log(e))
}

function attachEventListener() {
    const materialBtn = document.getElementById("materiale");
    const textBtn = document.getElementById("primaryText");
    const diamondBtn = document.getElementById("diamanti");
    const diamondColorBtn = document.getElementById("colore-diamanti");
    const submitBtn = document.getElementById("fake-submit");

    if(materialBtn == null || textBtn == null || submitBtn == null || diamondBtn == null || diamondColorBtn == null) {
        console.log("Non è possibile aggiungere la gestione eventi sui pulsanti, mancano uno o più componenti grafiche")
        return
    }

    materialBtn.addEventListener("change", (e) => {
        const target = e.target as HTMLInputElement
        const selectedMaterial = target.querySelector(`option[value="${target.value}"]`)

        if(selectedMaterial == null) {
            console.log("Impossibile aggiornare materiale, nessun materiale selezionato!")
            return
        }
        selectedMaterial.getAttribute("shape-value")

        const shapeValue = selectedMaterial.getAttribute("shape-value")!
        updateParameter('materiale', shapeValue)
            .then(() => cart.material = target.value)
            .then(() => getIdVariazione())
            .catch(e => console.log(e))
    })

    textBtn.addEventListener("change", (e) => {
        const target = e.target as HTMLInputElement
        setText(target.value)
    })

    $('#diamanti').on('change.select2', function () {
        setDiamanti()
            .catch(e => console.error(e))
    })

    diamondColorBtn.addEventListener("change", (e) => {
        const target = e.target as HTMLInputElement
        setGemsMaterial(target.value)
    })

    submitBtn.addEventListener("click", () => submitCart())
}

function insertSymbol(element: HTMLImageElement): string | undefined {
    const text = document.getElementById("primaryText") as HTMLInputElement

    if(text == null) {
        console.log("impossibile inserire simbolo, testo non trovato")
        return
    }
    let cursorPos = text.selectionStart

    if(cursorPos == null) return

    let actualText = text.value
    let textBefore = actualText.substring(0,  cursorPos);
    let textAfter  = actualText.substring( cursorPos, actualText.length);
    text.value = textBefore + element.getAttribute("data-value") + textAfter

    return text.value
}

async function loadDiamondsSelect(letters: string) {
     const diamanti = document.getElementById("diamanti") as HTMLSelectElement
    $("#diamanti").val([]).trigger("change")

     for (let i = 0; i < letters.length; i++) {
         const option = document.createElement("option")
         option.text = letters[i]
         option.value = String(i + 1)
         diamanti.append(option)
     }
 }

async function updateParameter(paramName: string, value: string) {
    const paramId = paramMap[paramName]

    if(paramId == null) {
        console.log(`Non è presente nessun ID per il parametro '${paramName}'`)
        return
    }
    const param = session.getParameterById(paramId);

    if(param == null) {
        console.log(`Su ShapeDiver non è presente nessun parametro con id '${paramId}'`)
        return
    }

    param.value = value;

    await session.customize();
}

function submitCart() {
    const w = window.top

    if(w == null) {
        console.log("elemento window non trovato, impossibile aggiungere al carrello")
        return
    }
    w.postMessage({channel: "cart", cart}, '*')
}

$("#init-ar").on("click", function () {
   initAR();
});

async function initAR() {
    //AR
    console.log("AR START");
    if (viewport.viewableInAR()) await viewport.viewInAR();
}